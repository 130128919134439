$(document).ready(function() {
		  "use strict";

	$('.header-menu').affix({
	      offset: {
	        top: $('section.header .header-strip').height()
	      }
	});


    setTimeout(function(){  $(document).equalize(".js-equal-heights",".js-equal-unit");  }, 500);

	$('.menu > ul > li:has( > ul)').addClass('menu-dropdown-icon');
	$('.menu > ul > li > ul:not(:has(ul))').addClass('normal-sub');
	$(".menu > ul").before("<a href=\"#\" class=\"menu-mobile\">&nbsp;</a>");
    $(".menu > ul > li").on('mouseover', function(e) {
        if ($(window).width() > 943) {
            $(this).children("ul").stop(true, false).fadeIn(150);
            e.preventDefault();
        }
    });
    $(".menu > ul > li").on('mouseout', function(e) {
        if ($(window).width() > 943) {
            $(this).children("ul").stop(true, false).fadeOut(150);
            e.preventDefault();
        }
    });
	// $(".menu > ul > li").hover(function(e) {
	// 	if ($(window).width() > 943) {
	// 		$(this).children("ul").stop(true, false).fadeToggle(150);
	//   		e.preventDefault();
	// 	}
	// });
	$(".menu > ul > li").click(function() {
	if ($(window).width() <= 943) {
	  $(this).children("ul").fadeToggle(150);
	}
	});
	$(".menu-mobile").click(function(e) {
	$(".menu > ul").toggleClass('show-on-mobile');
	e.preventDefault();
	});


    $(".sidear-services").click(function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $("#" + e.target.dataset.target).offset().top - 200
        }, 2000);
    });

});
	$(window).resize(function() {
	$(".menu > ul > li").children("ul").hide();
	$(".menu > ul").removeClass('show-on-mobile');
	});


	$(".panel-services .panel-container")
	.hover(function(e) {
		$footer_height = $(".panel-footer", this).outerHeight();
		// $(this).css("top","-"+$footer_height+"px");
		$(".panel-footer", this).fadeIn(100).css({display: "flex", top: "10px"});
	})
	.mouseleave(function(e) {
		$(this).css("top","0");
		$(".panel-footer", this).fadeOut(200);
	});


	$(".google-map").click(function () {
	    $('iframe',this).css("pointer-events", "auto");
	});

	$( ".google-map" ).mouseleave(function() {
	  $('iframe',this).css("pointer-events", "none"); 
	});

//accordion 

(function(){
	var d = document,
	accordionToggles = d.querySelectorAll('.js-accordionTrigger'),
	setAria,
	setAccordionAria,
	switchAccordion,
  touchSupported = ('ontouchstart' in window),
  pointerSupported = ('pointerdown' in window);
  
  skipClickDelay = function(e){
    e.preventDefault();
    e.target.click();
  }

		setAriaAttr = function(el, ariaType, newProperty){
		el.setAttribute(ariaType, newProperty);
	};
	setAccordionAria = function(el1, el2, expanded){
		switch(expanded) {
      case "true":
      	setAriaAttr(el1, 'aria-expanded', 'true');
      	setAriaAttr(el2, 'aria-hidden', 'false');
      	break;
      case "false":
      	setAriaAttr(el1, 'aria-expanded', 'false');
      	setAriaAttr(el2, 'aria-hidden', 'true');
      	break;
      default:
				break;
		}
	};
//function
switchAccordion = function(e) {
	e.preventDefault();
	var thisAnswer = e.target.parentNode.nextElementSibling;
	var thisQuestion = e.target;
	if(thisAnswer.classList.contains('is-collapsed')) {
		setAccordionAria(thisQuestion, thisAnswer, 'true');
	} else {
		setAccordionAria(thisQuestion, thisAnswer, 'false');
	}
  	thisQuestion.classList.toggle('is-collapsed');
  	thisQuestion.classList.toggle('is-expanded');
		thisAnswer.classList.toggle('is-collapsed');
		thisAnswer.classList.toggle('is-expanded');
 	
  	thisAnswer.classList.toggle('animateIn');
	};
	for (var i=0,len=accordionToggles.length; i<len; i++) {
		if(touchSupported) {
      accordionToggles[i].addEventListener('touchstart', skipClickDelay, false);
    }
    if(pointerSupported){
      accordionToggles[i].addEventListener('pointerdown', skipClickDelay, false);
    }
    accordionToggles[i].addEventListener('click', switchAccordion, false);
  }
})();

$.fn.equalize = function(measureID,apply) {
	var apply = (apply == '') ? measureID : apply;
	var _containers = [];
	$(measureID).each(function() {
		var _containers = [];
		$(apply,this).each(function() {
			$(this).css('min-height','auto');
			_containers.push($(this).outerHeight());
		});
		var max = Math.max.apply(null, _containers);
		$(apply,this).each(function() {
			$(this).css('min-height',max+'px');
		});
	});

    return this;
}


$('.client-carousel').slick({
	dots: false,
	infinite: true,
	speed: 300,
	slidesToShow: 5,
	slidesToScroll: 5,
	prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>',
	nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>',

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }

  ]
});

$('.testimonials-carousel').slick({
    autoplay: true,
    dots: true,
    appendDots: $('.slick-slider-dots'),
    infinite: true,
    speed: 300,
    delay: 6000,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: false,
    nextArrow: false,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }

  ]
});
